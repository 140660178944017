
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo, DicList } from '@/types/common'
import { DataList } from '@/types/rareTrees'
import ImportData from '@/components/importTemplateData/Index.vue'

@Component({
  name: 'RareTreesList',
  components: { ImportData }
})
export default class extends Vue {
  private searchInfo = {
    seedlingCode: '',
    plantName: '',
    projectId: '',
    protectionLevel: '',
    isFamous: '1'
  }

  private protectionLevelList: Array<DicInfo> = []
  private isShowImport = false
  private page = 1
  private size = 10
  private total = 0
  private tableData: DataList[] =[]
  private loading = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
    this.getProtectionLevel()
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.seedling.exportSeedlingData, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 获取保护等级列表
  getProtectionLevel () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, {
      dicType: 'protectionLevel'
    }).then((res) => {
      this.protectionLevelList = res.protectionLevel || []
    })
  }

  // 获取表格数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<DataList>}>(this.$apis.seedling.selectYhSeedlingByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增苗木
  addTrees () {
    this.$router.push('/resource/rareTreesBase/add')
  }

  editTrees (seedlingId: string) {
    this.$router.push({
      path: `/resource/rareTreesBase/edit${seedlingId}`
    })
  }

  goDetail (seedlingId: string) {
    this.$router.push({
      path: `/resource/rareTreesBase/detail${seedlingId}`
    })
  }

  deleteTrees (seedlingId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedling.deleteYhSeedling, {
        seedlingId
      }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
